import logo from './logo.svg';
import './App.css';
import Home from './Home/Home';
import AboutUs from './About/About';
import Footer from './Footer/Footer';
import YourComponent from './Body/Cara';
import Cara from './Body/Cara';
import Slider from './Carasoul/Ear/Slider';
import Slider1 from './Carasoul/Mangalsutra/Slider1';
import Slider2 from './Carasoul/Necless/Slider2';
import AdSpecial from './Advertise/AdSpecial';
import YourMainComponent from './Advertise/Cards';
import RouterDom from './RouterDom/RouterDom';
import Translater from './Body/Translate';

function App() {
  return (
    <div className='App'>
      <RouterDom />
      <Cara />
      <AboutUs />     
      <AdSpecial/>
      
      <YourMainComponent/>
      <Footer />
 
    </div>
  );
}

export default App;
