import React from 'react'
import './AdSpecial.css'
import img1 from '../Img/hm-gift-img-1.jpg'
import img2 from '../Img/hm-gift-img-2.jpg'

function AdSpecial() {
  return (
    <div className='special'>
        <div id='img1'><img src={img1}/></div>
        <div id='text'><h1>WE MAKE YOU FEEL SPECIAL</h1></div>
        <div id='img2'><img src={img2}/></div>       
    </div>
  )
}

export default AdSpecial