import React from 'react'
import { MdOutlineWhatsapp, MdFacebook, MdCall, MdEmail } from "react-icons/md";
import { RiInstagramLine } from "react-icons/ri";
import img1 from '../Img/logo-removebg.png'
import { Link } from 'react-router-dom';

function Footer() {
  const handleWhatsAppClick = () => {
    // Open WhatsApp
    window.open('https://api.whatsapp.com/send?phone=8275777027', '_blank');
  };

  const handleFacebookClick = () => {
    // Open Facebook
    window.open('https://www.facebook.com/profile.php?id=100091761473662', '_blank');
  };

  const handleInstagramClick = () => {
    // Open Instagram
    window.open('https://www.instagram.com/', '_blank');
  };

  const handlePhoneCallClick = () => {
    // Initiate a phone call
    window.open('tel:7249667527');
  };

  const handleEmailClick = () => {
    // Open default email client
    window.open('mailto:vashikarsaraff@gmail.com');
  };
  return (


<div className="px-4 pt-16 mx-auto bg-gray-100 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 mt-4 sm:mt-20">
      <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
        <div className="md:max-w-md lg:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
          <img src={img1}  alt="Logo" className="h-10 w-10 mr-2"/>
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
              Vashikar Saraf
            </span>
          </a>
          <div className="mt-4 lg:max-w-sm">
            <p className="text-sm text-gray-800">
              I am Vashikar Saraf Jewelery Showrooms in Islampur Sangli, Islampur-Maharashtra, Sangli is a top player in this category.
              Islampur-Maharashtra, Sangli. This well-known establishment serves as a one-stop destination for customers serving both local and international customers
              Islampur-Other parts of Maharashtra, Sangli.
            </p>
            <p className="mt-4 text-sm text-gray-800">
              In its journey, this business has established a strong foothold in t's ind
              The belief that customer satisfaction is as important as their products and services has helped this establishment gamer a lot.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
          <div>
            <p className="font-semibold tracking-wide text-gray-800">
              Category
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  Mangalsutra Design
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  Ring Design
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  Necklace Design
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  Bangles Design
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800">
              Navbar
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <Link to="/">Home</Link>
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <Link to="/about">About</Link>
                </a>
              </li>
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  <Link to="/design">Design</Link>
                </a>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800">Address</p>
            <ul className="mt-2 space-y-2">
              <li>
                <a
                  href="/"
                  className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                 1795, Gandhi Chouk, Islampur Dist:- sangli 415409
                </a>
              </li>

            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800">Contact Us</p>
            <ul className="mt-2 space-y-2">
              <li>
                <a
                  href="/"
                  className="text-gray-600 text-xl font-bold transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  +91 7249667527
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
        <p className="text-sm text-gray-600">
          © Copyright 2023 Vashikar Saraf All Rights Reserved To Business Mantra.
        </p>
        <div className="flex items-center mt-4 space-x-4 sm:mt-0">
          <div className="cursor-pointer hover:text-green-500 text-3xl" onClick={handleWhatsAppClick}>
            <MdOutlineWhatsapp />
            <i className="fab fa-whatsapp text-4xl "></i>
          </div>
          <div className="cursor-pointer hover:text-blue-500 text-3xl" onClick={handleFacebookClick}>
            <MdFacebook />
            <i className="fab fa-facebook text-4xl"></i>
          </div>
          <div className="cursor-pointer hover:text-pink-500 text-3xl" onClick={handleInstagramClick}>
            <RiInstagramLine />
            <i className="fab fa-instagram text-4xl"></i>
          </div>
          <div className="cursor-pointer hover:text-purple-500 text-3xl" onClick={handlePhoneCallClick}>
            <MdCall />
            <i className="fas fa-phone text-4xl"></i>
          </div>
          <div className="cursor-pointer hover:text-red-500 text-3xl" onClick={handleEmailClick}>
            <MdEmail />
            <i className="fas fa-envelope text-4xl"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer