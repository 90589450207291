import React, { useState } from 'react';
import './Home.css'; // Import your CSS file
import img1 from '../Img/12.png'
import img2 from '../Img/12__1_-removebg-preview copy.png'
import { Link } from 'react-router-dom';

function Home() {
  const [isActive, setIsActive] = useState(false);

  const handleHamburgerClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`container ${isActive ? 'active' : ''}`}>
      <div className="navbar">
        <div className="menu">
          <img src={img1} />
          <h3 className="logo">मे.वशीकर<span>सराफ</span></h3>
          <div className="hamburger-menu" onClick={handleHamburgerClick}>
            <div className="bar"></div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="main">
          <header>
            <div className="overlay">
              <h2 className="title">Exclusive Jewellery</h2>
              <p className="description">“Happiness Comes In The Box Of Jewellery”</p>
              <img src={img2} className='img19'/>
            </div>
          </header>
        </div>
        <div className="shadow one"></div>
        <div className="shadow two"></div>
      </div>
      <div className="links">
        <ul>
          <li className="active">
            <a href="#" style={{ "--i": '0.05s' }}><Link to="/">Home</Link></a>
          </li>
          <li>
            <a href="#" style={{ "--i": '0.1s' }}><Link to="/about">About</Link></a>
          </li>
          <li>
            <a href="#" style={{ "--i": '0.15s' }}><Link to="/design">Design</Link></a>
          </li>
          <li>
            <a href="#" style={{ "--i": '0.2s' }}><Link to="/footer">Contact</Link></a>
          </li>
         
        </ul>
      </div>
    </div>
  );
};

export default Home;
