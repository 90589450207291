
import React, { useState } from 'react';
import './Cara.css'; 
import img1 from '../Img/8.jpg'
import img2 from '../Img/7.jpg'
import img3 from '../Img/4.jpg'
import img4 from '../Img/13.jpg'
import img5 from '../Img/peakpx.jpg'


function Cara() {
    const text1Options = [
    "Why art is so important",
    "Why you shouldn't buy the new iPhone",
    "Is life actually real?",
    "How to learn JS in 2 months"
  ];
  const text2Options = [
    "69 min. read",
    "7 min. read",
    "8 min. read",
    "87,658.1277 min. read"
  ];
  const colorOptions = ["#EBB9D2", "#FE9968", "#7FE0EB", "#6CE5B1"];
  const imageOptions = [
"https://www.dishisjewels.com/blog/wp-content/uploads/2023/09/2-4-2-1024x512.jpg",
"https://www.wamanharipethesons.com/portalrepository/images/product/WHPS26236_0_r.jpg",
"https://www.chidambaramcovering.com/image/cache/catalog/Ear-rings/erg1351-buy-traditional-gold-look-jimiki-design-earring-indian-jhumkas-for-ladies-1-850x1000.jpg",
"https://www.sanvijewels.com/cdn/shop/products/IMG_5256.jpg?v=1618642809"  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % text1Options.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + text1Options.length) % text1Options.length);
  };

  return (
    <div className='Cara'> 
        <div className='caraimg'>
        <img src={img5}/>
        </div>
        <div id="carousel-wrapper">
      <div id="menu" style={{ background: colorOptions[currentIndex] }}>
        <div className='cattext'>
        <h1>Our Design</h1>
        <div className='imgcat'>
         <img src={img1}/>
         <img src={img2}/>
         <img src={img3}/>
         <img src={img4}/>
         </div>

        </div>
        <div id="current-option">
          <span
            id="current-option-text1"
            data-previous-text=""
            data-next-text={text1Options[currentIndex]}
          ></span>
          <span
            id="current-option-text2"
            data-previous-text=""
            data-next-text={text2Options[currentIndex]}
          ></span>
        </div>
        <button id="previous-option" onClick={handlePrevious}></button>
        <button id="next-option" onClick={handleNext}></button>
      </div>
      <div
        id="image"
        style={{
          backgroundImage: `url(${imageOptions[currentIndex]})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      ></div>
    </div></div>

  );
};

export default Cara;
