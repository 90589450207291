import React from 'react'
import { Routes,Route } from "react-router-dom";
import Home from '../Home/Home';
import AboutUs from '../About/About';
import Footer from '../Footer/Footer';
import AdSpecial from '../Advertise/AdSpecial';

function RouterDom() {
  return (
    <Routes>
    <Route exact path="/" element={<Home/>}/>
    <Route exact path='/about' element={<AboutUs/>}/>               
    <Route exact path="/design" element={<AdSpecial/>}/>
    <Route exact path="/footer" element={<Footer/>}/>
            

</Routes>
  )
}

export default RouterDom