// YourMainComponent.jsx

import React from 'react';
import './Cards.css'; // Import your CSS file
import img1 from '../Img/Shop_Occasion_Bridal.jpg'
import img2 from '../Img/Shop_Occasion_Dailywear.jpg'
import img3 from '../Img/Shop_Occasion_Partywear.jpg'
import img4 from '../Img/Shop_Occasion_Temple.jpg'

const YourMainComponent = () => {
  return (
    <div className="card-container">
      <div className="card">
        <div className="card-image" ><img src={img1} /></div>   
        <div className="card-content">
          <h2>Bridal</h2>
        </div>   
      </div>

      <div className="card">
        <div className="card-image" ><img src={img2} /></div>
        <div className="card-content">
          <h2>Dailywear</h2>
        </div>
      </div>

      <div className="card">
        <div className="card-image"  ><img src={img3} /></div>
        <div className="card-content">
          <h2>Partywear</h2>
        </div>
      </div>

      <div className="card">
        <div className="card-image"><img src={img4} /></div>
        <div className="card-content">
          <h2>Temple</h2>
        </div>
      </div>
    </div>
  );
};

export default YourMainComponent;
