import React from 'react'
import './Slider2.css'
// import img1 from 'https://scontent.fpnq7-4.fna.fbcdn.net/v/t39.30808-6/406444569_267326503002730_1697650401506546263_n.jpg?stp=dst-jpg_p526x296&_nc_cat=110&ccb=1-7&_nc_sid=3635dc&_nc_ohc=E4C6eQEcOJAAX8Prdx8&_nc_ht=scontent.fpnq7-4.fna&oh=00_AfDQ7w9Y7WkmvM4ac0tMdgflrbn8Y9slbVC2MOXCJhfEzg&oe=6576D875'
// import img2 from 'https://scontent.fpnq7-1.fna.fbcdn.net/v/t39.30808-6/386328560_233721646363216_5487709175282983427_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=3635dc&_nc_ohc=8k4tlET4w-EAX9fg909&_nc_ht=scontent.fpnq7-1.fna&oh=00_AfBD4EpEIvP-29JYJq5XFSVA68jVm48iZEsD2QxOQIX3Jg&oe=6576DA09'
// import img3 from 'https://scontent.fpnq7-6.fna.fbcdn.net/v/t39.30808-6/385066763_231295863272461_1961196142071890507_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=3635dc&_nc_ohc=C--E5U1c0jsAX90EOgA&_nc_ht=scontent.fpnq7-6.fna&oh=00_AfAT1bPmdUB3QJJalWHis1ohC_R6_iIuO7KghJRsM3_xNQ&oe=6576F0D8'
// import img4 from 'https://scontent.fpnq7-4.fna.fbcdn.net/v/t39.30808-6/380718313_224247133977334_8078049512047603757_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=3635dc&_nc_ohc=aqbE0CF560oAX9MWAgq&_nc_ht=scontent.fpnq7-4.fna&oh=00_AfDEvEJkmtAK9_jTnWLinK9oDJwaGtl0YL_jNPDzzrB13g&oe=65784F39'
// import img5 from 'https://scontent.fpnq7-1.fna.fbcdn.net/v/t39.30808-6/376400556_217564481312266_2287419545855062852_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=3635dc&_nc_ohc=B6wchPdM6AwAX_a6dAN&_nc_ht=scontent.fpnq7-1.fna&oh=00_AfA6_qagxv47_ez3GiqYFmU_ayTtquPefurletHv_UKwVw&oe=6577F83D'
// import img6 from 'https://scontent.fpnq7-2.fna.fbcdn.net/v/t39.30808-6/370046474_208564218878959_1461379904975395679_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=3635dc&_nc_ohc=yOlJ4-ai6vkAX9SXFcC&_nc_ht=scontent.fpnq7-2.fna&oh=00_AfBXDkaZPEjHr5OFP65CoByYxX-9zo0UrqnvZDDNPdQM5Q&oe=65776609'

function Slider2() {
  return (
    <div className='Ear2'>
       <div className="Eartext">
        <h1>Our Neckless Design </h1>
      </div>
      <div class="carousel-wrapper">
        <div class="carousel-container">
          <div class="carouseled">
            <div class="image-one"></div>
            <div class="image-two"></div>
            <div class="image-three"></div>
            <div class="image-four"></div>
            <div class="image-five"></div>
            <div class="image-six"></div>
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default Slider2